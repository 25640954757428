<template>
    <ObrasComponent />
</template>

<script>
import ObrasComponent from '@/components/Obras/ObrasComponent.vue';
export default {
    name: 'ObrasView',
    components: {
        ObrasComponent
    },
    data() {
        return {    
            
        }
    },

}
</script>

<style scoped lang="scss">

</style>